import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Cfop } from "@data/schemas/cfop/cfop";
import { DataList } from "@data/schemas/filter/data-list";
import { HttpParamsConfig } from "@shared/schemas/http-params-config";
import { convertHttpParamsToQueryString } from "@shared/utils/filter-params-utils";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class CfopService {
	readonly CFOPS_ENDPOINT = "cfops";

	constructor(private http: HttpClient) {}

	getCfops(httpParamsConfig?: HttpParamsConfig): Observable<DataList<Cfop>> {
		const params = convertHttpParamsToQueryString({
			...httpParamsConfig
		});

		return this.http.get<DataList<Cfop>>(this.CFOPS_ENDPOINT, {
			params: params,
			withCredentials: true
		});
	}
}
