import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	signal,
	SimpleChanges,
	ViewChild
} from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import {
	InvoiceConfigForm,
	TaxesDataStepFormGroup
} from "../types/invoice-config-form";
import { TaxesGroupService } from "@data/services/taxes-group.service";
import { TaxesStatus } from "@data/schemas/taxes-group/taxes-status";
import { Subject, takeUntil } from "rxjs";
import { InvoiceConfigSteps } from "../types/invoice-config-steps";
import { Cfop } from "@data/schemas/cfop/cfop";
import { HttpParamsConfig } from "@shared/schemas/http-params-config";
import { CfopService } from "@data/services/cfop.service";
import { WizardInvoiceConfigurationService } from "@data/services/wizard-invoice-configuration.service";
import { AtlasSelect } from "@asaasdev/atlas";
import { SelectOption } from "@asaasdev/atlas/dist/components/form/atlas-select/types";

@Component({
	selector: "app-taxes-data-step",
	templateUrl: "./taxes-data-step.component.html",
	styleUrls: ["./taxes-data-step.component.css"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaxesDataStepComponent implements OnInit, OnChanges, OnDestroy {
	private _onDestroy$ = new Subject<boolean>();

	readonly CFOP_IN_STATE_CODE = "5102";
	readonly CFOP_OF_STATE_CODE = "6102";

	@Input() currentStep: string = "";

	@Input()
	public formGroupName!: string;

	@Input() invoiceConfigForm!: FormGroup;

	protected form!: FormGroup<TaxesDataStepFormGroup>;

	protected icmsTypeEnumValues = signal<TaxesStatus[]>([]);
	protected ipiTypeEnumValues = signal<TaxesStatus[]>([]);
	protected pisCofinsEnumValues = signal<TaxesStatus[]>([]);
	protected cfopInStateValue = signal<Cfop | undefined>(
		this.cfopEmptyValue()
	);
	protected cfopOfStateValue = signal<Cfop | undefined>(
		this.cfopEmptyValue()
	);

	protected taxRegime: string = "";
	protected TAX_REGIME_NORMAL = "normalRegime";

	@ViewChild("icmsInStateSelect")
	icmsInStateSelect!: ElementRef<AtlasSelect>;

	@ViewChild("pisInStateSelect")
	pisInStateSelect!: ElementRef<AtlasSelect>;

	@ViewChild("cofinsInStateSelect")
	cofinsInStateSelect!: ElementRef<AtlasSelect>;

	@ViewChild("ipiInStateSelect")
	ipiInStateSelect!: ElementRef<AtlasSelect>;

	@ViewChild("icmsOfStateSelect")
	icmsOfStateSelect!: ElementRef<AtlasSelect>;

	@ViewChild("pisOfStateSelect")
	pisOfStateSelect!: ElementRef<AtlasSelect>;

	@ViewChild("cofinsOfStateSelect")
	cofinsOfStateSelect!: ElementRef<AtlasSelect>;

	@ViewChild("ipiOfStateSelect")
	ipiOfStateSelect!: ElementRef<AtlasSelect>;

	constructor(
		private rootFormGroup: FormGroupDirective,
		private taxesGroupService: TaxesGroupService,
		private cfopService: CfopService,
		private wizardInvoiceConfigurationService: WizardInvoiceConfigurationService
	) {}

	ngOnInit() {
		this.form = this.rootFormGroup.control.get(
			this.formGroupName
		) as FormGroup<TaxesDataStepFormGroup>;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.currentStep.currentValue == InvoiceConfigSteps.TAXES) {
			this.wizardInvoiceConfigurationService.trackWizard(
				"step_taxes_group"
			);

			this.setTaxRegime();
			if (this.taxRegime === this.TAX_REGIME_NORMAL) {
				return;
			}

			this.setDefaultValues();
			this.getIcmsTypeEnumValues();
			this.getIpiTypeEnumValues();
			this.getPisCofinsEnumValues();
			this.getCfops();
		}
	}

	ngOnDestroy(): void {
		this._onDestroy$.next(true);
	}

	setTaxRegime() {
		this.taxRegime =
			(this.invoiceConfigForm as FormGroup<InvoiceConfigForm>)?.controls
				?.fiscalData?.controls?.taxRegime?.value ?? "";
	}

	protected getIcmsInStateTaxes() {
		const selectedOption =
			this.icmsInStateSelect.nativeElement?.getSelectedOptions() as SelectOption;
		this.form.controls.icmsInState.setValue(selectedOption.value);
	}

	protected getPisInStateTaxes() {
		const selectedOption =
			this.pisInStateSelect.nativeElement?.getSelectedOptions() as SelectOption;
		this.form.controls.pisInState.setValue(selectedOption.value);
	}

	protected getCofinsInStateTaxes() {
		const selectedOption =
			this.cofinsInStateSelect.nativeElement?.getSelectedOptions() as SelectOption;
		this.form.controls.cofinsInState.setValue(selectedOption.value);
	}

	protected getIpiInStateTaxes() {
		const selectedOption =
			this.ipiInStateSelect.nativeElement?.getSelectedOptions() as SelectOption;
		this.form.controls.ipiInState.setValue(selectedOption.value);
	}

	protected getIcmsOfStateTaxes() {
		const selectedOption =
			this.icmsOfStateSelect.nativeElement?.getSelectedOptions() as SelectOption;
		this.form.controls.icmsOfState.setValue(selectedOption.value);
	}

	protected getPisOfStateTaxes() {
		const selectedOption =
			this.pisOfStateSelect.nativeElement?.getSelectedOptions() as SelectOption;
		this.form.controls.pisOfState.setValue(selectedOption.value);
	}

	protected getCofinsOfStateTaxes() {
		const selectedOption =
			this.cofinsOfStateSelect.nativeElement?.getSelectedOptions() as SelectOption;
		this.form.controls.cofinsOfState.setValue(selectedOption.value);
	}

	protected getIpiOfStateTaxes() {
		const selectedOption =
			this.ipiOfStateSelect.nativeElement?.getSelectedOptions() as SelectOption;
		this.form.controls.ipiOfState.setValue(selectedOption.value);
	}

	private getIcmsTypeEnumValues() {
		this.taxesGroupService
			.getIcmsTypeEnumValues()
			.pipe(takeUntil(this._onDestroy$))
			.subscribe((data) => {
				const filteredData = data.filter((item) =>
					[
						"TRIBUTADO_SIMPLESNACIONAL_COMCREDITO",
						"TRIBUTADO_SIMPLESNACIONAL_SEMCREDITO",
						"TRIBUTADO_ISENCAOICMSSIMPLES",
						"TRIBUTADO_SIMPLESIMUNE_300",
						"TRIBUTADO_OUTROS"
					].includes(item.id)
				);
				this.icmsTypeEnumValues.set(filteredData);
			});
	}

	private getIpiTypeEnumValues() {
		this.taxesGroupService
			.getIpiTypeEnumValues()
			.pipe(takeUntil(this._onDestroy$))
			.subscribe((data) => {
				const filteredData = data.filter((item) =>
					["SAIDA_NAOTRIBUTADA", "OUTRAS_SAIDAS"].includes(item.id)
				);
				this.ipiTypeEnumValues.set(filteredData);
			});
	}

	private getPisCofinsEnumValues() {
		this.taxesGroupService
			.getPisCofinsTypeEnumValues()
			.pipe(takeUntil(this._onDestroy$))
			.subscribe((data) => {
				const filteredData = data.filter((item) =>
					[
						"OPERACAO_SEMINCIDENCIACONTRIBUICAO",
						"OUTRAS_OPERACOESSAIDA",
						"OUTRAS_OPERACOES"
					].includes(item.id)
				);
				this.pisCofinsEnumValues.set(filteredData);
			});
	}

	private getCfops() {
		const httpParamsInState: HttpParamsConfig = {
			fields: [{ key: "codigo", value: this.CFOP_IN_STATE_CODE }]
		};

		const httpParamsOfState: HttpParamsConfig = {
			fields: [{ key: "codigo", value: this.CFOP_OF_STATE_CODE }]
		};

		this.cfopService
			.getCfops(httpParamsInState)
			.pipe(takeUntil(this._onDestroy$))
			.subscribe((data) => {
				if (data) {
					this.cfopInStateValue.set(data.content[0]);
					this.form.controls.cfopInState.setValue(data.content[0].id);
					this.form.controls.cfopInStateName.setValue(
						data.content[0].codigo +
							" - " +
							data.content[0].descricao
					);
				}
			});

		this.cfopService
			.getCfops(httpParamsOfState)
			.pipe(takeUntil(this._onDestroy$))
			.subscribe((data) => {
				if (data) {
					this.cfopOfStateValue.set(data.content[0]);
					this.form.controls.cfopOfState.setValue(data.content[0].id);
					this.form.controls.cfopOfStateName.setValue(
						data.content[0].codigo +
							" - " +
							data.content[0].descricao
					);
				}
			});
	}

	private setDefaultValues(): void {
		this.form.controls.cfopInState.setValue(
			parseInt(this.CFOP_IN_STATE_CODE)
		);
		this.form.controls.cfopInStateName.setValue("");
		this.form.controls.icmsInState.setValue(
			"TRIBUTADO_SIMPLESNACIONAL_SEMCREDITO"
		);
		this.form.controls.pisInState.setValue(
			"OPERACAO_SEMINCIDENCIACONTRIBUICAO"
		);
		this.form.controls.cofinsInState.setValue(
			"OPERACAO_SEMINCIDENCIACONTRIBUICAO"
		);
		this.form.controls.ipiInState.setValue("SAIDA_NAOTRIBUTADA");
		this.form.controls.cfopOfState.setValue(
			parseInt(this.CFOP_OF_STATE_CODE)
		);
		this.form.controls.cfopOfStateName.setValue("");
		this.form.controls.icmsOfState.setValue(
			"TRIBUTADO_SIMPLESNACIONAL_SEMCREDITO"
		);
		this.form.controls.pisOfState.setValue(
			"OPERACAO_SEMINCIDENCIACONTRIBUICAO"
		);
		this.form.controls.cofinsOfState.setValue(
			"OPERACAO_SEMINCIDENCIACONTRIBUICAO"
		);
		this.form.controls.ipiOfState.setValue("SAIDA_NAOTRIBUTADA");
	}

	protected cfopEmptyValue(): Cfop {
		return {
			id: 0,
			codigo: "",
			descricao: ""
		};
	}
}
