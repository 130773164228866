import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from "@angular/core";
import {
	FormArray,
	FormControl,
	FormGroup,
	FormGroupDirective
} from "@angular/forms";
import { WizardInvoiceConfigurationService } from "@data/services/wizard-invoice-configuration.service";
import { AtlasToggleItemChangeEvent } from "@modules/financeiro/components/create-new-receipt-wizard/types/atlas-toggle-item-change-event";
import { AutomataTranslateService } from "@shared/services/automata-translate.service";
import { IAtlasMaskedInput } from "@shared/types/atlas/i-atlas-masked-input";
import { Subject } from "rxjs";
import { InterestedActors } from "../types/interested-actors";
import { FiscalDataStepFormGroup } from "../types/invoice-config-form";

@Component({
	selector: "app-fiscal-data-step",
	templateUrl: "./fiscal-data-step.component.html",
	styleUrl: "./fiscal-data-step.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiscalDataStepComponent implements OnInit, OnDestroy {
	private _onDestroy$ = new Subject<boolean>();

	@Input() currentStep: string = "";

	@Input() invoiceConfigForm: FormGroup | null = null;

	@Input()
	public formGroupName!: string;

	@ViewChild("newActorTaxpayerRegistration")
	protected newActorTaxpayerRegistration!: ElementRef<IAtlasMaskedInput>;

	@ViewChild("newActorName")
	protected newActorName!: ElementRef<IAtlasMaskedInput>;

	protected interestedActorsDisableControl = false;

	protected environmentToggleSelected = "approval";

	protected form!: FormGroup<FiscalDataStepFormGroup>;

	constructor(
		private rootFormGroup: FormGroupDirective,
		private automataTranslateService: AutomataTranslateService,
		private wizardInvoiceConfigurationService: WizardInvoiceConfigurationService
	) {}

	ngOnInit(): void {
		this.form = this.rootFormGroup.control.get(
			this.formGroupName
		) as FormGroup<FiscalDataStepFormGroup>;
		this.wizardInvoiceConfigurationService.trackWizard("step_tax_data");
		this.wizardInvoiceConfigurationService.trackWizard(
			"emission_environment",
			{ environment_config: "PRODUCAO" }
		);
	}

	ngOnDestroy(): void {
		this._onDestroy$.next(true);
	}

	get interestedActors(): FormArray {
		return this.form.controls.interestedActors;
	}

	protected includeNewActor(): void {
		if (this.isInvalidNewActorFields()) {
			this.handleInterestedActorsValidation();
			return;
		}

		const newActor: InterestedActors = {
			id: this.interestedActors.length + 1,
			actorName:
				this.form.controls.actorGroup.controls.newActorName.value!,
			taxpayerRegistration:
				this.form.controls.actorGroup.controls
					.newActorTaxpayerRegistration.value!
		};

		this.interestedActors.push(new FormControl(newActor));

		this.form.controls.actorGroup.controls.newActorName.reset();
		this.form.controls.actorGroup.controls.newActorTaxpayerRegistration.setValue(
			""
		);

		if (this.interestedActors.length === 10) {
			this.interestedActorsDisableControl = true;
			return;
		}
	}

	protected removeActor(id: number): void {
		const filteredControls = this.interestedActors.controls.filter(
			(control) =>
				(control as FormControl<InterestedActors>).value.id !== id
		) as FormControl<InterestedActors>[];

		this.form.setControl(
			"interestedActors",
			new FormArray(filteredControls)
		);

		if (this.interestedActors.length < 10) {
			this.interestedActorsDisableControl = false;
		}
	}

	protected allowOnlyNumbers(event: KeyboardEvent): boolean {
		const key = event.key;
		if (key >= "0" && key <= "9") {
			return true;
		}
		event.preventDefault();
		return false;
	}

	protected changeEnvironment(event: Event): void {
		const atlasItemToggleChangeEvent = event as AtlasToggleItemChangeEvent;

		this.form.controls.environmentConfig.patchValue(
			atlasItemToggleChangeEvent.detail.checked
				? "production"
				: "approval"
		);

		this.wizardInvoiceConfigurationService.trackWizard(
			"emission_environment",
			{
				environment_config: atlasItemToggleChangeEvent.detail.checked
					? "PRODUCAO"
					: "HOMOLOGACAO"
			}
		);
	}

	private isInvalidNewActorFields(): boolean {
		const newActorName =
			this.form.controls.actorGroup.controls.newActorName.value;
		const newActorTaxpayerRegistration =
			this.form.controls.actorGroup.controls.newActorTaxpayerRegistration
				.value;

		return (
			!newActorName ||
			!this.form.controls.actorGroup.controls.newActorTaxpayerRegistration
				.value ||
			!this.newActorTaxpayerRegistration.nativeElement.validateNewValue(
				newActorTaxpayerRegistration!
			).isValid ||
			this.verifyIfHasTaxpayerRegistration()
		);
	}

	private verifyIfHasTaxpayerRegistration(): boolean {
		const newActorTaxpayerRegistration =
			this.form.controls.actorGroup.controls.newActorTaxpayerRegistration
				.value;

		if (
			this.interestedActors.controls.find(
				(control) =>
					(control as FormControl<InterestedActors>).value
						.taxpayerRegistration === newActorTaxpayerRegistration!
			)
		) {
			return true;
		}
		return false;
	}

	private handleInterestedActorsValidation(): void {
		const newActorTaxpayerRegistration =
			this.form.controls.actorGroup.controls.newActorTaxpayerRegistration
				.value;

		const newActorName =
			this.form.controls.actorGroup.controls.newActorName.value;

		if (!newActorName) {
			this.newActorName.nativeElement.setStatusWithMessage(
				"error",
				this.automataTranslateService.getTranslationSync(
					"NFE_WIZARD_CONFIGURATION.LABEL.REQUIRED_FIELD"
				)
			);
		} else {
			this.newActorName.nativeElement.setStatusWithMessage("none", "");
		}

		if (!newActorTaxpayerRegistration) {
			this.newActorTaxpayerRegistration.nativeElement.setStatusWithMessage(
				"error",
				this.automataTranslateService.getTranslationSync(
					"NFE_WIZARD_CONFIGURATION.LABEL.REQUIRED_FIELD"
				)
			);
			return;
		} else {
			this.newActorTaxpayerRegistration.nativeElement.setStatusWithMessage(
				"none",
				""
			);
		}

		if (
			!this.newActorTaxpayerRegistration.nativeElement.validateNewValue(
				newActorTaxpayerRegistration
			).isValid
		) {
			this.newActorTaxpayerRegistration.nativeElement.setStatusWithMessage(
				"error",
				this.automataTranslateService.getTranslationSync(
					"NFE_WIZARD_CONFIGURATION.LABEL.TAXPAYER_REGISTRATION_INVALID"
				)
			);
		} else {
			this.newActorTaxpayerRegistration.nativeElement.setStatusWithMessage(
				"none",
				""
			);
		}

		if (this.verifyIfHasTaxpayerRegistration()) {
			this.newActorTaxpayerRegistration.nativeElement.setStatusWithMessage(
				"error",
				this.automataTranslateService.getTranslationSync(
					"NFE_WIZARD_CONFIGURATION.LABEL.ALREADY_EXISTS_WITH_THIS_TAXPAYER_REGISTRATION"
				)
			);
		} else {
			this.newActorTaxpayerRegistration.nativeElement.setStatusWithMessage(
				"none",
				""
			);
		}
	}
}
