<atlas-form [formGroup]="form">
	<atlas-grid>
		<atlas-row>
			<atlas-col lg="12">
				@if (taxRegime === TAX_REGIME_NORMAL) {
					<atlas-section>
						<atlas-empty-state
							illustration="files-notification-notebook"
							[header]="
								'NFE_WIZARD_CONFIGURATION.HEADER.TAXES_EMPTY_STATE'
									| translate
							"
							[description]="
								'NFE_WIZARD_CONFIGURATION.LABEL.TAXES_EMPTY_STATE_DESCRIPTION'
									| translate
							"
						></atlas-empty-state>
					</atlas-section>
				} @else {
					<atlas-section
						hide-optional=""
						[header]="
							'NFE_WIZARD_CONFIGURATION.HEADER.TAXATION_SUGGESTIONS'
								| translate
						"
					>
						<atlas-text muted="">
							{{
								"NFE_WIZARD_CONFIGURATION.LABEL.TAXATION_SUGGESTIONS_DESCRIPTION"
									| translate
							}}</atlas-text
						></atlas-section
					>

					<atlas-section
						hide-optional=""
						[header]="
							'NFE_WIZARD_CONFIGURATION.HEADER.IN_STATE_SALE'
								| translate
						"
						[description]="
							'NFE_WIZARD_CONFIGURATION.LABEL.IN_STATE_SALE_DESCRIPTION'
								| translate
						"
					>
						<atlas-grid>
							<atlas-row>
								<atlas-col lg="6">
									<atlas-table>
										<atlas-table-header slot="header">
											<atlas-table-col
												name="taxType"
											></atlas-table-col>
											<atlas-table-col
												name="taxName"
												size="lg"
												ellipsis
											></atlas-table-col>
										</atlas-table-header>

										<atlas-table-body slot="body">
											<atlas-table-row>
												<atlas-table-col
													>CFOP</atlas-table-col
												>
												<atlas-table-col>
													<atlas-text>
														{{
															cfopInStateValue()
																?.codigo +
																" - " +
																cfopInStateValue()
																	?.descricao
														}}
													</atlas-text>
												</atlas-table-col>
											</atlas-table-row>

											<atlas-table-row>
												<atlas-table-col
													>ICMS</atlas-table-col
												>
												<atlas-table-col>
													<atlas-select
														#icmsInStateSelect
														[required]="true"
														enable-search=""
														name="icmsInState"
														value-key="id"
														label-key="descricao"
														[value]="
															this.form.controls
																.icmsInState
																.value
														"
														default-value="TRIBUTADO_SIMPLESNACIONAL_SEMCREDITO"
														(atlas-select-change)="
															getIcmsInStateTaxes()
														"
													>
														@if (
															icmsTypeEnumValues()
														) {
															@for (
																icmsType of icmsTypeEnumValues();
																track icmsType.id
															) {
																<atlas-option
																	[label]="
																		icmsType.descricao
																	"
																	[value]="
																		icmsType.id
																	"
																></atlas-option>
															}
														}
													</atlas-select>
												</atlas-table-col>
											</atlas-table-row>

											<atlas-table-row>
												<atlas-table-col
													>PIS</atlas-table-col
												>
												<atlas-table-col>
													<atlas-select
														#pisInStateSelect
														[required]="true"
														enable-search=""
														name="pisInState"
														value-key="id"
														label-key="descricao"
														[value]="
															this.form.controls
																.pisInState
																.value
														"
														default-value="OPERACAO_SEMINCIDENCIACONTRIBUICAO"
														(atlas-select-change)="
															getPisInStateTaxes()
														"
													>
														@if (
															pisCofinsEnumValues()
														) {
															@for (
																pisType of pisCofinsEnumValues();
																track pisType.id
															) {
																<atlas-option
																	[label]="
																		pisType.descricao
																	"
																	[value]="
																		pisType.id
																	"
																></atlas-option>
															}
														}
													</atlas-select>
												</atlas-table-col>
											</atlas-table-row>

											<atlas-table-row>
												<atlas-table-col
													>COFINS</atlas-table-col
												>
												<atlas-table-col>
													<atlas-select
														#cofinsInStateSelect
														[required]="true"
														enable-search=""
														name="cofinsInState"
														value-key="id"
														label-key="descricao"
														[value]="
															this.form.controls
																.cofinsInState
																.value
														"
														default-value="OPERACAO_SEMINCIDENCIACONTRIBUICAO"
														(atlas-select-change)="
															getCofinsInStateTaxes()
														"
													>
														@if (
															pisCofinsEnumValues()
														) {
															@for (
																cofinsType of pisCofinsEnumValues();
																track cofinsType.id
															) {
																<atlas-option
																	[label]="
																		cofinsType.descricao
																	"
																	[value]="
																		cofinsType.id
																	"
																></atlas-option>
															}
														}
													</atlas-select>
												</atlas-table-col>
											</atlas-table-row>

											<atlas-table-row>
												<atlas-table-col
													>IPI</atlas-table-col
												>
												<atlas-table-col>
													<atlas-select
														#ipiInStateSelect
														[required]="true"
														[required]="true"
														enable-search=""
														name="ipiInState"
														value-key="id"
														label-key="descricao"
														[value]="
															this.form.controls
																.ipiInState
																.value
														"
														default-value="SAIDA_NAOTRIBUTADA"
														(atlas-select-change)="
															getIpiInStateTaxes()
														"
													>
														@if (
															ipiTypeEnumValues()
														) {
															@for (
																ipiType of ipiTypeEnumValues();
																track ipiType.id
															) {
																<atlas-option
																	[label]="
																		ipiType.descricao
																	"
																	[value]="
																		ipiType.id
																	"
																></atlas-option>
															}
														}
													</atlas-select>
												</atlas-table-col>
											</atlas-table-row>
										</atlas-table-body>
									</atlas-table>
								</atlas-col>
							</atlas-row>
						</atlas-grid>
					</atlas-section>

					<atlas-section
						hide-optional=""
						[header]="
							'NFE_WIZARD_CONFIGURATION.HEADER.OF_STATES_SALE'
								| translate
						"
						[description]="
							'NFE_WIZARD_CONFIGURATION.LABEL.OF_STATES_SALE_DESCRIPTION'
								| translate
						"
					>
						<atlas-grid>
							<atlas-row>
								<atlas-col lg="6">
									<atlas-table>
										<atlas-table-header slot="header">
											<atlas-table-col
												name="taxType"
											></atlas-table-col>
											<atlas-table-col
												name="taxName"
												size="lg"
												ellipsis
											></atlas-table-col>
										</atlas-table-header>

										<atlas-table-body slot="body">
											<atlas-table-row>
												<atlas-table-col
													>CFOP</atlas-table-col
												>
												<atlas-table-col>
													<atlas-text>
														{{
															cfopOfStateValue()
																?.codigo +
																" - " +
																cfopOfStateValue()
																	?.descricao
														}}
													</atlas-text>
												</atlas-table-col>
											</atlas-table-row>

											<atlas-table-row>
												<atlas-table-col
													>ICMS</atlas-table-col
												>
												<atlas-table-col>
													<atlas-select
														#icmsOfStateSelect
														[required]="true"
														enable-search=""
														name="icmsOfState"
														value-key="id"
														label-key="descricao"
														[value]="
															this.form.controls
																.icmsOfState
																.value
														"
														default-value="TRIBUTADO_SIMPLESNACIONAL_SEMCREDITO"
														(atlas-select-change)="
															getIcmsOfStateTaxes()
														"
													>
														@if (
															icmsTypeEnumValues()
														) {
															@for (
																icmsType of icmsTypeEnumValues();
																track icmsType.id
															) {
																<atlas-option
																	[label]="
																		icmsType.descricao
																	"
																	[value]="
																		icmsType.id
																	"
																></atlas-option>
															}
														}
													</atlas-select>
												</atlas-table-col>
											</atlas-table-row>

											<atlas-table-row>
												<atlas-table-col
													>PIS</atlas-table-col
												>
												<atlas-table-col>
													<atlas-select
														#pisOfStateSelect
														[required]="true"
														enable-search=""
														name="pisOfState"
														value-key="id"
														label-key="descricao"
														[value]="
															this.form.controls
																.pisOfState
																.value
														"
														default-value="OPERACAO_SEMINCIDENCIACONTRIBUICAO"
														(atlas-select-change)="
															getPisOfStateTaxes()
														"
													>
														@if (
															pisCofinsEnumValues()
														) {
															@for (
																pisType of pisCofinsEnumValues();
																track pisType.id
															) {
																<atlas-option
																	[label]="
																		pisType.descricao
																	"
																	[value]="
																		pisType.id
																	"
																></atlas-option>
															}
														}
													</atlas-select>
												</atlas-table-col>
											</atlas-table-row>

											<atlas-table-row>
												<atlas-table-col
													>COFINS</atlas-table-col
												>
												<atlas-table-col>
													<atlas-select
														#cofinsOfStateSelect
														[required]="true"
														[required]="true"
														enable-search=""
														name="cofinsOfState"
														value-key="id"
														label-key="descricao"
														[value]="
															this.form.controls
																.cofinsOfState
																.value
														"
														default-value="OPERACAO_SEMINCIDENCIACONTRIBUICAO"
														(atlas-select-change)="
															getCofinsOfStateTaxes()
														"
													>
														@if (
															pisCofinsEnumValues()
														) {
															@for (
																cofinsType of pisCofinsEnumValues();
																track cofinsType.id
															) {
																<atlas-option
																	[label]="
																		cofinsType.descricao
																	"
																	[value]="
																		cofinsType.id
																	"
																></atlas-option>
															}
														}
													</atlas-select>
												</atlas-table-col>
											</atlas-table-row>

											<atlas-table-row>
												<atlas-table-col
													>IPI</atlas-table-col
												>
												<atlas-table-col>
													<atlas-select
														#ipiOfStateSelect
														[required]="true"
														[required]="true"
														enable-search=""
														name="ipiOfState"
														value-key="id"
														label-key="descricao"
														[value]="
															this.form.controls
																.ipiOfState
																.value
														"
														default-value="SAIDA_NAOTRIBUTADA"
														(atlas-select-change)="
															getIpiOfStateTaxes()
														"
													>
														@if (
															ipiTypeEnumValues()
														) {
															@for (
																ipiType of ipiTypeEnumValues();
																track ipiType.id
															) {
																<atlas-option
																	[label]="
																		ipiType.descricao
																	"
																	[value]="
																		ipiType.id
																	"
																></atlas-option>
															}
														}
													</atlas-select>
												</atlas-table-col>
											</atlas-table-row>
										</atlas-table-body>
									</atlas-table>
								</atlas-col>
							</atlas-row>
						</atlas-grid>
					</atlas-section>
				}
			</atlas-col>
		</atlas-row>
	</atlas-grid>
</atlas-form>
