<atlas-form [formGroup]="invoiceConfigForm">
	@if (loading()) {
		<div class="atlas-loading-backdrop">
			<atlas-loading
				[message]="
					'NFE_WIZARD_CONFIGURATION.LABEL.SUBMIT_WIZARD' | translate
				"
			></atlas-loading>
		</div>
	}

	<atlas-wizard
		#atlasWizard
		[header]="'NFE_WIZARD_CONFIGURATION.TITLE' | translate"
		(atlas-wizard-close)="closeWizard()"
		(atlas-wizard-change-step)="setStep($event)"
		(atlas-wizard-next-step)="nextStep()"
		(atlas-wizard-finish)="submitWizard($event)"
	>
		<atlas-wizard-step
			[name]="steps.FISCAL_DATA"
			[header]="'NFE_WIZARD_CONFIGURATION.HEADER.FISCAL_DATA' | translate"
			[active]="true"
		>
			<app-fiscal-data-step
				[invoiceConfigForm]="invoiceConfigForm"
				[formGroupName]="steps.FISCAL_DATA"
				[currentStep]="currentStep"
			></app-fiscal-data-step
		></atlas-wizard-step>
		<atlas-wizard-step
			[name]="steps.DIGITAL_CERTIFICATE"
			[header]="
				'NFE_WIZARD_CONFIGURATION.HEADER.DIGITAL_CERTIFICATE'
					| translate
			"
		>
			<app-digital-certificate-data-step
				[invoiceConfigForm]="invoiceConfigForm"
				[formGroupName]="steps.DIGITAL_CERTIFICATE"
				[currentStep]="currentStep"
			></app-digital-certificate-data-step>
		</atlas-wizard-step>
		<atlas-wizard-step
			[name]="steps.TAXES"
			[header]="'NFE_WIZARD_CONFIGURATION.HEADER.TAXES' | translate"
		>
			<app-taxes-data-step
				[invoiceConfigForm]="invoiceConfigForm"
				[formGroupName]="steps.TAXES"
				[currentStep]="currentStep"
			></app-taxes-data-step>
		</atlas-wizard-step>

		<atlas-wizard-step
			[name]="steps.SUMMARY"
			[header]="'NFE_WIZARD_CONFIGURATION.HEADER.SUMMARY' | translate"
		>
			@if (currentStep === steps.SUMMARY) {
				<app-invoice-configuration-summary-step
					[invoiceConfigForm]="invoiceConfigForm"
					[currentStep]="currentStep"
				></app-invoice-configuration-summary-step>
			}
		</atlas-wizard-step>
	</atlas-wizard>
</atlas-form>
