import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	signal,
	ViewChild
} from "@angular/core";
import {
	FormArray,
	FormBuilder,
	FormControl,
	Validators
} from "@angular/forms";
import { AtlasWizard } from "@asaasdev/atlas";
import { AuthService } from "@core/services/auth/auth.service";
import {
	DigitalCertificateValidationFile,
	DigitalCertificateValidationResponse
} from "@data/schemas/digital-certificate/digital-certificate-validation-file";
import {
	Actor,
	DigitalCertificate,
	InvoiceConfiguration
} from "@data/schemas/invoice-configuration/invoice-configuration";
import { OnboardingUser } from "@data/schemas/onboarding-user";
import { DigitalCertificateService } from "@data/services/digital-certificate.service";
import { OnboardingUserService } from "@data/services/onboarding-user.service";
import { WizardInvoiceConfigurationService } from "@data/services/wizard-invoice-configuration.service";
import { AlertsService } from "@shared/components/atlas/alerts/services";
import { GLOBAL_ALERT_KEY } from "@shared/components/atlas/alerts/types";
import { AutomataTranslateService } from "@shared/services/automata-translate.service";
import { AtlasWizardChangeStep } from "@shared/types/atlas/atlas-wizard-change-step";
import { fileToBase64 } from "@shared/utils/file-to-base64";
import { BsModalRef } from "ngx-bootstrap/modal";
import { map, Observable, Subject, switchMap, takeUntil } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { CertificateModel } from "web-pki";
import { DigitalCertificateStepController } from "./step-controller/digital-certificate-step-controller";
import { InterestedActors } from "./types/interested-actors";
import {
	ActorGroup,
	DigitalCertificateDataStepFormGroup,
	FiscalDataStepFormGroup,
	TaxesDataStepFormGroup
} from "./types/invoice-config-form";
import { InvoiceConfigSteps } from "./types/invoice-config-steps";
import { TaxRegimeEnum } from "./types/tax-regime-enum.enum";
import { TaxesConfig } from "./types/taxes-config";

@Component({
	selector: "app-invoice-configuration-wizard",
	templateUrl: "./invoice-configuration-wizard.component.html",
	styleUrl: "./invoice-configuration-wizard.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceConfigurationWizardComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	public interactionId: string = uuidv4();
	public openedByChecklistSidebar: OnboardingUser | undefined = undefined;
	CHECKLIST_SETUP_INVOICE_KEY = "nfeChecked";

	private _onDestroy$ = new Subject<boolean>();

	protected currentStep: string = InvoiceConfigSteps.FISCAL_DATA;
	protected loading = signal(false);

	@ViewChild("atlasWizard")
	protected atlasWizard!: ElementRef<AtlasWizard>;

	steps = InvoiceConfigSteps;

	constructor(
		private bsModalRef: BsModalRef,
		private formBuilder: FormBuilder,
		private alertsService: AlertsService,
		private digitalCertificateService: DigitalCertificateService,
		private automataTranslateService: AutomataTranslateService,
		private wizardInvoiceConfigurationService: WizardInvoiceConfigurationService,
		private onboardingUserService: OnboardingUserService,
		private authService: AuthService
	) {}

	protected invoiceConfigForm = this.formBuilder.group({
		fiscalData: this.formBuilder.group<FiscalDataStepFormGroup>({
			stateRegistration: new FormControl<number | null>(null, {
				validators: Validators.required
			}),
			taxRegime: new FormControl<string>("", Validators.required),
			environmentConfig: new FormControl<string>(
				"production",
				Validators.required
			),
			nfeNumber: new FormControl<string | null>("", {
				validators: [Validators.required]
			}),
			nfeSeries: new FormControl<string>("", Validators.required),
			interestedActors: new FormArray<FormControl<InterestedActors>>(
				[],
				Validators.maxLength(10)
			),
			actorGroup: this.formBuilder.group<ActorGroup>({
				newActorName: new FormControl<string | null>(""),
				newActorTaxpayerRegistration: new FormControl<string | null>("")
			})
		}),
		digitalCertificate:
			this.formBuilder.group<DigitalCertificateDataStepFormGroup>({
				digitalCertificateFile: new FormControl<File | null>(null),
				digitalCertificateType: new FormControl<string>("a1"),
				digitalCertificateFileName: new FormControl<string | null>(""),
				digitalCertificatePassword: new FormControl<string | null>(""),
				digitalCertificateExpirationDate: new FormControl<
					string | null
				>(""),
				digitalCertificateValidated: new FormControl<boolean>(
					false,
					Validators.requiredTrue
				),
				digitalCertificateA3: new FormControl<CertificateModel | null>(
					null
				)
			}),
		taxesData: this.formBuilder.group<TaxesDataStepFormGroup>({
			cfopInState: new FormControl<number | null>(5102),
			cfopInStateName: new FormControl<string | null>(""),
			icmsInState: new FormControl<string | null>(
				"TRIBUTADO_SIMPLESNACIONAL_SEMCREDITO"
			),
			pisInState: new FormControl<string | null>(
				"OPERACAO_SEMINCIDENCIACONTRIBUICAO"
			),
			cofinsInState: new FormControl<string | null>(
				"OPERACAO_SEMINCIDENCIACONTRIBUICAO"
			),
			ipiInState: new FormControl<string | null>("SAIDA_NAOTRIBUTADA"),
			cfopOfState: new FormControl<number | null>(null),
			cfopOfStateName: new FormControl<string | null>(""),
			icmsOfState: new FormControl<string | null>(
				"TRIBUTADO_SIMPLESNACIONAL_SEMCREDITO"
			),
			pisOfState: new FormControl<string | null>(
				"OPERACAO_SEMINCIDENCIACONTRIBUICAO"
			),
			cofinsOfState: new FormControl<string | null>(
				"OPERACAO_SEMINCIDENCIACONTRIBUICAO"
			),
			ipiOfState: new FormControl<string | null>("SAIDA_NAOTRIBUTADA")
		})
	});

	ngOnInit() {
		this.wizardInvoiceConfigurationService.trackWizard("click_configure", {
			interaction_id: this.interactionId
		});
	}

	ngAfterViewInit(): void {
		this.atlasWizard.nativeElement.addStepController(
			InvoiceConfigSteps.DIGITAL_CERTIFICATE.toString(),
			new DigitalCertificateStepController(
				this.invoiceConfigForm.controls.digitalCertificate.controls.digitalCertificateValidated
			)
		);
	}

	ngOnDestroy(): void {
		this._onDestroy$.next(true);
		this._onDestroy$.complete();
	}

	protected closeWizard(): void {
		this.bsModalRef.hide();
	}

	protected setStep(event: Event): void {
		const detail = (event as AtlasWizardChangeStep)?.detail;
		this.currentStep = detail?.step?.name;
	}

	protected nextStep(): void {
		if (
			this.currentStep ===
				InvoiceConfigSteps.DIGITAL_CERTIFICATE.toString() &&
			!this.invoiceConfigForm.controls.digitalCertificate.controls
				.digitalCertificateValidated.value
		) {
			this.validateDigitalCertificate();
		}
	}

	protected async submitWizard(event: Event): Promise<void> {
		this.wizardInvoiceConfigurationService.trackWizard("summary_finish");
		event.preventDefault();
		this.loading.set(true);
		this.wizardInvoiceConfigurationService
			.sendInvoiceConfiguration(
				await this.createInvoiceConfigurationObject()
			)
			.pipe(
				switchMap((_) => {
					return this.updateChecklistSidebar();
				}),
				takeUntil(this._onDestroy$)
			)
			.subscribe({
				next: () => {
					this.loading.set(false);
					this.alertsService.success({
						key: GLOBAL_ALERT_KEY,
						message:
							this.automataTranslateService.getTranslationSync(
								"NFE_WIZARD_CONFIGURATION.LABEL.SUBMIT_WIZARD_SUCCESS"
							)
					});
					this.wizardInvoiceConfigurationService.emitUpdate();
					this.bsModalRef.hide();
				},
				error: () => {
					this.loading.set(false);
				}
			});
	}

	private async createInvoiceConfigurationObject(): Promise<InvoiceConfiguration> {
		const invoiceConfiguration: InvoiceConfiguration = {
			stateRegistration:
				this.invoiceConfigForm.controls.fiscalData.controls.stateRegistration.value?.toString() ||
				"",
			taxRegime: {
				id: this.getTaxRegime()
			},
			environmentConfig: {
				id: this.getEnvironmentConfig()
			},
			nfeNumber: this.removeDots(
				this.invoiceConfigForm.controls.fiscalData.controls.nfeNumber
					.value || ""
			),
			nfeSeries: this.removeDots(
				this.invoiceConfigForm.controls.fiscalData.controls.nfeSeries
					.value || ""
			),
			interestedActors: this.getInterestedActors(),
			digitalCertificate: await this.getDigitalCertificate()
		};

		if (
			this.invoiceConfigForm.controls.fiscalData.controls.taxRegime
				.value != "normalRegime"
		) {
			invoiceConfiguration.taxesGroup = this.createTaxesGroupArray();
		}

		return invoiceConfiguration;
	}

	private createTaxesGroupArray(): TaxesConfig[] {
		const inStateTaxes: TaxesConfig = {
			cfop: {
				id: this.invoiceConfigForm.controls.taxesData.controls
					.cfopInState.value!
			},
			icms: {
				id: this.invoiceConfigForm.controls.taxesData.controls
					.icmsInState.value!
			},
			pis: {
				id: this.invoiceConfigForm.controls.taxesData.controls
					.pisInState.value!
			},
			cofins: {
				id: this.invoiceConfigForm.controls.taxesData.controls
					.cofinsInState.value!
			},
			ipi: {
				id: this.invoiceConfigForm.controls.taxesData.controls
					.ipiInState.value!
			},
			saleType: {
				id: "DENTRO_ESTADO"
			}
		};

		const ofStateTaxes: TaxesConfig = {
			cfop: {
				id: this.invoiceConfigForm.controls.taxesData.controls
					.cfopOfState.value!
			},
			icms: {
				id: this.invoiceConfigForm.controls.taxesData.controls
					.icmsOfState.value!
			},
			pis: {
				id: this.invoiceConfigForm.controls.taxesData.controls
					.pisOfState.value!
			},
			cofins: {
				id: this.invoiceConfigForm.controls.taxesData.controls
					.cofinsOfState.value!
			},
			ipi: {
				id: this.invoiceConfigForm.controls.taxesData.controls
					.ipiOfState.value!
			},
			saleType: {
				id: "FORA_ESTADO"
			}
		};

		return [inStateTaxes, ofStateTaxes];
	}

	private async getDigitalCertificate(): Promise<
		Partial<DigitalCertificate>
	> {
		const certificateType =
			this.invoiceConfigForm.controls.digitalCertificate.controls
				.digitalCertificateType.value;

		if (certificateType === "a3") {
			return this.getCertificateA3();
		}

		return this.getCertificateA1();
	}

	private getCertificateA3(): Partial<DigitalCertificate> {
		return {
			name: "",
			password: "",
			expirationDate: "",
			content:
				this.invoiceConfigForm.controls.digitalCertificate.controls
					.digitalCertificateA3.value?.thumbprint || "",
			certificateType: {
				id: "A3_TODOS"
			}
		};
	}

	private async getCertificateA1(): Promise<Partial<DigitalCertificate>> {
		const base64Content = await fileToBase64(
			this.invoiceConfigForm.controls.digitalCertificate.controls
				.digitalCertificateFile.value!
		);

		return {
			name:
				this.invoiceConfigForm.controls.digitalCertificate.controls
					.digitalCertificateFile.value?.name || "",
			password:
				this.invoiceConfigForm.controls.digitalCertificate.controls
					.digitalCertificatePassword.value || "",
			expirationDate:
				this.invoiceConfigForm.controls.digitalCertificate.controls
					.digitalCertificateExpirationDate.value || "",
			certificateType: {
				id: "A1_TODOS"
			},
			content: base64Content as string
		};
	}

	private getTaxRegime(): string {
		const taxRegime =
			this.invoiceConfigForm.controls.fiscalData.controls.taxRegime.value;

		if (!taxRegime) {
			return "";
		}

		const taxRegimeMap: { [key: string]: TaxRegimeEnum } = {
			nationalSimple: TaxRegimeEnum.SIMP_NACIONAL,
			simpleWithGrossIncome: TaxRegimeEnum.SIMP_REC_BRUTA,
			normalRegime: TaxRegimeEnum.REGIME_NORMAL,
			mei: TaxRegimeEnum.SIMP_NACIONAL_MEI
		};

		return taxRegimeMap[taxRegime];
	}

	private getEnvironmentConfig(): string {
		const environmentConfig =
			this.invoiceConfigForm.controls.fiscalData.controls
				.environmentConfig.value;

		return environmentConfig === "approval" ? "HOMOLOGACAO" : "PRODUCAO";
	}

	private getInterestedActors(): Actor[] {
		return this.invoiceConfigForm.controls.fiscalData.controls.interestedActors.value.map(
			(actor: InterestedActors) => {
				return {
					actorName: actor.actorName,
					taxpayerRegistration: actor.taxpayerRegistration
				};
			}
		);
	}

	private removeDots(value: string | null): string {
		return value ? value.replace(/\./g, "") : "";
	}

	private validateDigitalCertificate(): void {
		if (
			this.invoiceConfigForm.controls.digitalCertificate.controls
				.digitalCertificateType.value === "a1"
		) {
			this.validateDigitalCertificateA1();
		}
	}

	private validateDigitalCertificateA1() {
		const file =
			this.invoiceConfigForm.controls.digitalCertificate.controls
				.digitalCertificateFile.value!;

		if (!file) {
			this.alertsService.error({
				key: GLOBAL_ALERT_KEY,
				message: "O envio do certificado digital é obrigatório"
			});
			return;
		}

		const validation: DigitalCertificateValidationFile = {
			file: file,
			password:
				this.invoiceConfigForm.controls.digitalCertificate.controls
					.digitalCertificatePassword.value!
		};

		this.digitalCertificateService
			.validateFile(validation)
			.pipe(takeUntil(this._onDestroy$))
			.subscribe({
				next: (response: DigitalCertificateValidationResponse) => {
					this.invoiceConfigForm.controls.digitalCertificate.controls.digitalCertificateValidated.setValue(
						true
					);

					this.invoiceConfigForm.controls.digitalCertificate.controls.digitalCertificateExpirationDate.setValue(
						response.expirationDate
					);

					this.alertsService.success({
						key: GLOBAL_ALERT_KEY,
						message:
							this.automataTranslateService.getTranslationSync(
								"NFE_WIZARD_CONFIGURATION.LABEL.VALIDATED_SUCCESSFULLY"
							)
					});
				}
			});
	}

	private updateChecklistSidebar(): Observable<void> {
		const userId = this.authService.getUserLogin()?.idUsuario || 0;
		return this.onboardingUserService
			.updateOnboardingUser(userId, {
				...this.openedByChecklistSidebar,
				[this.CHECKLIST_SETUP_INVOICE_KEY]: true
			} as Partial<OnboardingUser>)
			.pipe(
				map(() => {
					this.onboardingUserService.getOnboardingUser(userId);
					this.onboardingUserService.emitForceUpdateOnboardingUser();
				}),
				takeUntil(this._onDestroy$)
			);
	}
}
