import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges
} from "@angular/core";
import { InvoiceConfigSteps } from "../types/invoice-config-steps";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { AutomataTranslateService } from "@shared/services/automata-translate.service";
import { InterestedActors } from "../types/interested-actors";
import { WizardInvoiceConfigurationService } from "@data/services/wizard-invoice-configuration.service";
import { InvoiceConfigForm } from "@modules/invoicing/pages/invoice/invoice-configuration-wizard/types/invoice-config-form";

@Component({
	selector: "app-invoice-configuration-summary-step",
	templateUrl: "./invoice-configuration-summary-step.component.html",
	styleUrl: "./invoice-configuration-summary-step.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceConfigurationSummaryStepComponent
	implements OnInit, OnChanges
{
	steps = InvoiceConfigSteps;

	@Input() currentStep: string = "";

	@Input() invoiceConfigForm!: FormGroup;

	protected taxRegimeLabel = "";
	protected taxRegime: string = "";
	protected TAX_REGIME_NORMAL = "normalRegime";

	constructor(
		private automataTranslateService: AutomataTranslateService,
		private wizardInvoiceConfigurationService: WizardInvoiceConfigurationService
	) {}

	ngOnInit(): void {
		this.getTaxRegimeLabel();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.currentStep.currentValue == InvoiceConfigSteps.SUMMARY) {
			this.wizardInvoiceConfigurationService.trackWizard("step_summary");
			this.setTaxRegime();
		}
	}

	setTaxRegime() {
		this.taxRegime =
			(this.invoiceConfigForm as FormGroup<InvoiceConfigForm>)?.controls
				?.fiscalData?.controls?.taxRegime?.value ?? "";
	}

	protected getFormControlValueFiscalDataStep(name: string): string {
		if (!this.invoiceConfigForm?.controls.fiscalData.get(name)?.value) {
			return this.getUninformedTranslation();
		}

		return String(
			this.invoiceConfigForm?.controls.fiscalData.get(name)?.value
		);
	}

	protected getFormControlValueDigitalCertificateDataStep(
		name: string
	): string {
		if (
			!this.invoiceConfigForm.controls.digitalCertificate.get(name)!.value
		) {
			return this.getUninformedTranslation();
		}

		return String(
			this.invoiceConfigForm.controls.digitalCertificate.get(name)!.value
		);
	}

	protected getDigitalCertificateTypeLabel(): string {
		const type: string = this.getFormControlValueDigitalCertificateDataStep(
			"digitalCertificateType"
		);

		return type === "a1" ? "Certificado A1" : "Certificado A3";
	}

	protected getActorsInformation(): {
		firstActorName: string;
		firstActorTaxpayerRegistration: string;
		count: string;
	} {
		const interestedActors = this.invoiceConfigForm?.get(
			"fiscalData.interestedActors"
		) as FormArray<FormControl<InterestedActors>>;

		if (interestedActors) {
			const firstActorName =
				interestedActors.value[0]?.actorName ||
				this.getUninformedTranslation();
			const firstActorTaxpayerRegistration =
				interestedActors.value[0]?.taxpayerRegistration ||
				this.getUninformedTranslation();
			const count =
				interestedActors.length - 1 > 0
					? "+" + String(interestedActors.length - 1)
					: this.getUninformedTranslation();

			return {
				firstActorName,
				firstActorTaxpayerRegistration,
				count
			};
		}

		return {
			firstActorName: this.getUninformedTranslation(),
			firstActorTaxpayerRegistration: this.getUninformedTranslation(),
			count: this.getUninformedTranslation()
		};
	}

	protected getEnvironmentConfigLabel(): string {
		const environmentConfig =
			this.getFormControlValueFiscalDataStep("environmentConfig");

		return environmentConfig === "approval" ? "Homologação" : "Produção";
	}

	protected getCfopInStateName(): string {
		return this.invoiceConfigForm?.controls.taxesData.get(
			"cfopInStateName"
		)!.value as string;
	}

	protected getCfopOfStateName(): string {
		return this.invoiceConfigForm?.controls.taxesData.get(
			"cfopOfStateName"
		)!.value as string;
	}

	private getUninformedTranslation(): string {
		return this.automataTranslateService.getTranslationSync(
			"CREATE_RECEIPT.SUMMARY_DATA_STEP.UNINFORMED"
		);
	}

	private getTaxRegimeLabel(): void {
		const taxRegime = this.getFormControlValueFiscalDataStep("taxRegime");

		const taxRegimeMap: { [key: string]: string } = {
			nationalSimple: this.automataTranslateService.getTranslationSync(
				"NFE_WIZARD_CONFIGURATION.LABEL.SIMPLE_NATIONAL"
			),
			simpleWithGrossIncome:
				this.automataTranslateService.getTranslationSync(
					"NFE_WIZARD_CONFIGURATION.LABEL.SIMPLE_WITH_GROSS_INCOME"
				),
			normalRegime: this.automataTranslateService.getTranslationSync(
				"NFE_WIZARD_CONFIGURATION.LABEL.NORMAL_REGIME"
			),
			mei: "MEI"
		};

		this.taxRegimeLabel =
			taxRegimeMap[taxRegime] || this.getUninformedTranslation();
	}
}
