import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TaxesStatus } from "@data/schemas/taxes-group/taxes-status";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class TaxesGroupService {
	constructor(private http: HttpClient) {}

	getIcmsTypeEnumValues(): Observable<TaxesStatus[]> {
		return this.http.get<TaxesStatus[]>("icms-products/icms-tax-status", {
			withCredentials: true
		});
	}

	getPisCofinsTypeEnumValues(): Observable<TaxesStatus[]> {
		return this.http.get<TaxesStatus[]>(
			"cofins-products/pis-cofins-tax-status",
			{
				withCredentials: true
			}
		);
	}

	getIpiTypeEnumValues(): Observable<TaxesStatus[]> {
		return this.http.get<TaxesStatus[]>("ipi-products/ipi-tax-status", {
			withCredentials: true
		});
	}
}
